import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme) => ({
  rootDesktop: {
    position: 'absolute',
    // bottom: theme.spacing(2),
    top: '48%',
    right: theme.spacing(2),
    zIndex: 1,
    backgroundColor: 'rgba(164,198,39,0.13)',
  },
  rootMobile: {
    position: 'absolute',
    top: '43%',
    right: theme.spacing(0),
    zIndex: 1,
    backgroundColor: 'rgba(164,198,39,0.13)',
  },
}));

const VolumeControlFloat = (props) => {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 1000,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Zoom in={!trigger}>
      {isMobile ? (
        <div onClick={props.callbackFn} role='presentation' className={classes.rootMobile}>
          {children}
        </div>
      ) : (
        <div onClick={props.callbackFn} role='presentation' className={classes.rootDesktop}>
          {children}
        </div>
      )}
    </Zoom>
  );
};

VolumeControlFloat.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default VolumeControlFloat;
